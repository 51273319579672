<script setup lang="ts">
import { defineProps, withDefaults } from "vue";
import { StoredProcedureProgress } from "o365.pwa.modules.client.steps.StoredProcedureProgress.ts";
import StoredProcedureProgressOffline from "o365.pwa.vue.components.steps.StoredProcedureProgressOffline.vue";
import StoredProcedureProgressOnline from "o365.pwa.vue.components.steps.StoredProcedureProgressOnline.vue";
interface IProps {
  syncStepProgress: StoredProcedureProgress;
  currentStep: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  currentStep: false,
});

</script>

<script lang="ts">
export default {
  name: "StoredProcedureProgress",
};
</script>

<template>
  <StoredProcedureProgressOffline v-if="props.syncStepProgress.syncType === 'OFFLINE-SYNC'" :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep"/>
  <StoredProcedureProgressOnline v-else-if="props.syncStepProgress.syncType === 'ONLINE-SYNC'" :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep"/>
</template>
